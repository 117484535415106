
.login
	background rgba(0,0,0,0.4)
	min-height 100%
	height 100%
	width 100%
	position absolute
	top 0
	left 0
	h1
		color #fff
		font-size 24px
	p
		color #fff
		font-size 13px
	form
		margin-top 30px
		text-align center
		font-size 13px
		label
			width 100px
			text-align right
			padding-right 20px
			display inline-block
		input
			user-select none
			width 150px
			border-radius 15px
			border none
			box-shadow none
			background #fff
			padding 10px 15px
		div
			margin-bottom 20px
		.btn2
			padding 10px 15px
			background #fff
			border none
			box-shadow none
			border-radius 15px
	.btn
		font-size 13px
		line-height 18px
		padding 10px 20px
		text-align left
		background #fff
		display inline-block
		border-radius 25px
		margin-top 20px
		min-width 200px
		user-select none
		cursor pointer
		svg, img
			width 18px
			vertical-align middle
			margin-right 10px
			margin-left 30px
		&.registerbtn
			text-align center
.back
	color #fff
	font-size 11px
	padding 20px
	cursor pointer
.notLog
	max-width 300px
	width 300px
	position absolute
	top 50%
	left calc(50% - 150px)
	transform translateY(-60%)
	span
		border-radius 15px
		background #fff
		padding 10px 15px
		user-select none
		cursor pointer
.register
	margin-top 40px
	.btn
		margin-top 0

