.login {
  background: rgba(0,0,0,0.4);
  min-height: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.login h1 {
  color: #fff;
  font-size: 24px;
}
.login p {
  color: #fff;
  font-size: 13px;
}
.login form {
  margin-top: 30px;
  text-align: center;
  font-size: 13px;
}
.login form label {
  width: 100px;
  text-align: right;
  padding-right: 20px;
  display: inline-block;
}
.login form input {
  user-select: none;
  width: 150px;
  border-radius: 15px;
  border: none;
  box-shadow: none;
  background: #fff;
  padding: 10px 15px;
}
.login form div {
  margin-bottom: 20px;
}
.login form .btn2 {
  padding: 10px 15px;
  background: #fff;
  border: none;
  box-shadow: none;
  border-radius: 15px;
}
.login .btn {
  font-size: 13px;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
  background: #fff;
  display: inline-block;
  border-radius: 25px;
  margin-top: 20px;
  min-width: 200px;
  user-select: none;
  cursor: pointer;
}
.login .btn svg,
.login .btn img {
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 30px;
}
.login .btn.registerbtn {
  text-align: center;
}
.back {
  color: #fff;
  font-size: 11px;
  padding: 20px;
  cursor: pointer;
}
.notLog {
  max-width: 300px;
  width: 300px;
  position: absolute;
  top: 50%;
  left: calc(50% - 150px);
  transform: translateY(-60%);
}
.notLog span {
  border-radius: 15px;
  background: #fff;
  padding: 10px 15px;
  user-select: none;
  cursor: pointer;
}
.register {
  margin-top: 40px;
}
.register .btn {
  margin-top: 0;
}
/*# sourceMappingURL=src/views/Login.css.map */